import React from "react";

import { GiArchiveResearch } from "react-icons/gi";
import { FaPeopleLine } from "react-icons/fa6";
import { MdGroups2 } from "react-icons/md";

const pillars = [
  {
    heading: "Research",
    description:
      "Research in the field of climate and Policy Evaluation in the field of Climate Change and Mitigating Strategies",
    icon: () => {
      return <GiArchiveResearch size={40} color="white" />;
    },
  },
  {
    heading: "Youth Leardership",
    description: "Climate cadets who are passionate about climate change",
    icon: () => {
      return <FaPeopleLine size={40} color="white" />;
    },
  },
  {
    heading: "Community Engagement",
    description:
      "To make a real impact, launching the projects based on needs locally",
    icon: () => {
      return <MdGroups2 size={40} color="white" />;
    },
  },
];

const WhyChooseUs = () => {
  return (
    <div className="bg-black">
      <section
        id="features"
        className="relative block px-6 py-10 md:py-20 md:px-10  border-t border-b border-neutral-900 bg-neutral-900/30"
      >
        <div className="relative mx-auto max-w-5xl text-center">
          <span className="text-gray-400 my-3 flex items-center justify-center font-medium uppercase tracking-wider">
            Why choose us
          </span>
          <h2 className="block w-full bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-transparent text-3xl sm:text-4xl">
            Our three major pillars
          </h2>
          {/* <p className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-400"> */}
          {/*   Lorem ipsum dolor sit amet, officia excepteur ex fugiat */}
          {/*   reprehenderit enim labore culpa sint ad nisi Lorem pariatur mollit */}
          {/* </p> */}
        </div>

        <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
          {pillars.map((pillar, i) => (
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow"
            >
              <div
                className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%); border-color: rgb(93, 79, 240);",
                }}
              >
                {pillar.icon()}
              </div>
              <h3 className="mt-6 text-gray-300">{pillar.heading}</h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
                {pillar.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUs;
