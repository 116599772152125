import React from "react";
import Nav from "./Navbar";
import hero from "../assets/images/hero.avif";
import PrimaryButton from "./common/PrimaryButton";

const Hero = () => {
  return (
    <div className="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
      <div className="absolute inset-0">
        <img
          src={hero}
          alt="Background"
          className="object-cover object-center w-full h-full"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      <div className="relative z-10 h-screen flex flex-col items-stretch w-full text-center">
        <Nav />
        <div data-aos="fade-up" className="my-auto">
          <h1 className="text-5xl font-bold leading-tight mb-4">
            𝐂𝐥𝐢𝐦𝐚𝐭𝐞 𝐂𝐚𝐝𝐞𝐭𝐬 𝐂𝐨𝐥𝐥𝐞𝐜𝐭𝐢𝐯𝐞 (𝐂-𝐂𝐮𝐛𝐞)
          </h1>
          <p className="text-lg text-gray-300 mb-8">
            An inspiring initiative that empowers young individuals to actively
            engage in climate policy and decision-making processes.
          </p>
          <PrimaryButton className="text-xl py-3 px-[30px!important]">
            Join Us
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Hero;
