import React from "react";

type Blog = {
  name: string;
  description: string;
  href: string;
  duration: string;
  image: string;
  date: {
    month: string;
    date: string;
  };
};

const blogs: Blog[] = [
  {
    name: "Lorem ipsum dolor sit amet",
    description:
      "Lorem ipsum dolor sit amet, qui minim labore adipisicing minim sint cillum sint consectetur cupidatat.",
    href: "#",
    duration: "2 min read",
    image: "https://images.pexels.com/photos/196667/pexels-photo-196667.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500",
    date: {
      month: "JAN",
      date: "01",
    },
  },

  {
    name: "Lorem ipsum dolor sit amet",
    description:
      "Lorem ipsum dolor sit amet, qui minim labore adipisicing minim sint cillum sint consectetur cupidatat.",
    href: "#",
    duration: "2 min read",
    image: "https://images.pexels.com/photos/257816/pexels-photo-257816.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500",
    date: {
      month: "JAN",
      date: "01",
    },
  },

  {
    name: "Lorem ipsum dolor sit amet",
    description:
      "Lorem ipsum dolor sit amet, qui minim labore adipisicing minim sint cillum sint consectetur cupidatat.",
    href: "#",
    duration: "2 min read",
    image: "https://images.pexels.com/photos/196667/pexels-photo-196667.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500",
    date: {
      month: "JAN",
      date: "01",
    },
  },
];

const Blogs = () => {
  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="relative mx-auto max-w-5xl text-center">
        <span className="text-black my-3 flex items-center justify-center font-medium uppercase tracking-wider">
          Blogs
        </span>
        <h2 className="block w-full text-black font-bold text-transparent text-3xl sm:text-4xl">
          Lorem ipsum dolor sit amet, qui minim labore
        </h2>
        <p className="mx-auto my-4 pb-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-400">
          Lorem ipsum dolor sit amet, officia excepteur ex fugiat reprehenderit
          enim labore culpa sint ad nisi Lorem pariatur mollit
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
        {blogs.map((blog, i) => (
          <div className="rounded overflow-hidden shadow-lg">
            <div className="relative">
              <a href={blog.href}>
                <img
                  className="w-full"
                  src={blog.image}
                  alt="Sunset in the mountains"
                />
                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
              </a>
              <a href={blog.href}>
                <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  Photos
                </div>
              </a>

              <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                <span className="font-bold">{blog.date.date}</span>
                <small>{blog.date.month}</small>
              </div>
            </div>
            <div className="px-6 py-4">
              <a
                href="#"
                className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
              >
                {blog.name}
              </a>
              <p className="text-gray-500 text-sm">{blog.description}</p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center">
              <span className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center">
                <span className="ml-1">{blog.duration}</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
