import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Hero from "./components/Hero";
import WhyChooseUs from "./components/WhyChooseUs";

import AOS from "aos";
import "aos/dist/aos.css";
import AboutUs from "./components/AboutUs";
import Blogs from "./components/BlogsTTV";
import Footer from "./components/Footer";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App">
      <Hero />
      <WhyChooseUs />
      <AboutUs />
      <Blogs/>
      <Footer/>
    </div>
  );
}

export default App;
